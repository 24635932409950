
import * as strapiRuntime$nHVYFnW316 from '/home/runner/work/joliedesign/joliedesign/website/node_modules/@nuxt/image-edge/dist/runtime/providers/strapi'
import * as ipxRuntime$wesaIotIX4 from '/home/runner/work/joliedesign/joliedesign/website/node_modules/@nuxt/image-edge/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['strapi']: { provider: strapiRuntime$nHVYFnW316, defaults: {"baseURL":"https://strapi.joliedesign.nl/uploads/"} },
  ['ipx']: { provider: ipxRuntime$wesaIotIX4, defaults: undefined }
}
        