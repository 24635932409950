import SeoFallback from "~/typings/partials/seoFallback";

export default function useStrapiSeo() {
  const route = useRoute();

  return (data: Ref<unknown>, fallbackValues: Partial<SeoFallback> = {}) => {
    return useSeoMeta({
      ogUrl: data.value?.seo?.canonicalURL || route.fullPath,
      ogTitle: data.value?.seo?.metaTitle || toValue(fallbackValues.title),
      description: data.value?.seo?.metaDescription || toValue(fallbackValues.description),
      ogDescription: data.value?.seo?.metaDescription || toValue(fallbackValues.description),
      ogImage: toValue(fallbackValues.imageUrl),
      twitterCard: 'summary_large_image',
    });
  }

  

}
